<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col>
                        <h3>
                            <img :src="data.image" alt="Logo" v-show="picture" width="28" height="28" @load="picture = true" @error="picture = false">
                            <b-icon icon="window" variant="orange" v-show="!picture"></b-icon>&nbsp;
                            {{ data.name[locale] }}
                        </h3>
                    </b-col>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.vdi.pools.headers.general') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.poolId') }}:</b-td>
                                    <b-td>{{ data.id }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.image') }}:</b-td>
                                    <b-td>{{ data.url }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.name') }}:</b-td>
                                    <b-td>{{ data.name[locale] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.description') }}:</b-td>
                                    <b-td>{{ data.description[locale] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.form.public') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.public ? 'text-success' : 'text-danger'" :icon="data.public ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.form.disabled') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.disabled ? 'text-danger' : 'text-success'" :icon="data.disabled ? 'times' : 'check'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.apps.detail.createdAt') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <h4>{{ $t('pages.vdi.apps.headers.rdpFile') }}</h4>
                        <vue-codeditor style="font-size: 14px" v-model="data.rdpTemplate" mode="eiffel" theme="monokai" :readonly="true"/>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="5">
                        <h4>{{ $t('pages.vdi.apps.headers.variables') }}</h4>
                        <b-table-simple small striped>
                            <b-thead>
                                <b-tr>
                                    <b-th>Feldname</b-th>
                                    <b-th>Variablenname</b-th>
                                    <b-th>Wert</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(val, key) in data.rdpTemplateVariables" :key="key">
                                    <b-td class="small">{{ val[getLocale()] }}</b-td>
                                    <b-td class="small">{{ val.name }}</b-td>
                                    <b-td class="small"><strong>{{ val.value }}</strong></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <hr>
                <user-to-resource-list :data="data.users" attr="users_apps"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import appService from "../../../services/appService";
import UserToResourceList from "../../../components/UserToResourceList";
import mainServices from "../../../services/mainServices";

export default {
    name: "Detail",
    components: {UserToResourceList},
    props: ["id"],
    data: () => ({
        data: {
            name: {"de":"","en":""},
            description: {"de":"","en":""},
            template: '',
            templateVariables: ''
        },
        picture: false,
        locale: 'de',
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.data.rdpTemplate = appService.getTemplate();
        this.loadData();
        this.locale = mainServices.getLocale();
    },
    methods: {
        getLocale() {
            return localStorage.getItem("locale");
        },
        loadData() {
            appService.get(this.id).then(response => {
                response.data.name = JSON.parse(response.data.name)
                response.data.description = JSON.parse(response.data.description)
                //response.data.rdpTemplateVariables = JSON.parse(response.data.rdpTemplateVariables);
                this.data = response.data;
            }).catch(error => {
                this.errorHandler(error);
            });
        }
    }
};
</script>

<style scoped>

</style>
